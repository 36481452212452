<template>
  <octo-table
    show-filters
    :action="endpoints.DATATABLES.deals_to_confirm"
    :fields="fields"
    :filters="filters"
    :name="name"
    @onResetFilters="filters = {}"
  >
    <template v-slot:registry="data">
      <div>
        {{ data.row.registry | optional('surname') }}
        {{ data.row.registry | optional('name') }}
      </div>
      <div class="small">
        <span>Comm. </span>
        <label-theme-component>
          {{ users[data.row.user_id] | optional('lastname') }}
        {{ users[data.row.user_id] | optional('firstname') }}
        </label-theme-component>
      </div>
    </template>

    <template v-slot:status="data">
      <badge  v-if="data.row.status" :type="data.row.status | dealStatusColor" class="text-uppercase">
        {{ $t('deal.status.' + data.row.status) }}
      </badge>
      <div v-if="data.row.sub_status" class="small text-uppercase">
        {{$t('deal.status.' + data.row.sub_status)}}
      </div>
    </template>

    <template v-slot:date="data">
      <div class="d-flex align-items-center justify-content-center small">
        {{data.row.date_in | date}}
        <div class="mx-2" v-if="data.row.date_out"> <octo-icon icon="right-arrow"/> </div>
        <div v-if="data.row.date_out">{{data.row.date_out | date}}</div>
      </div>
    </template>

    <template v-slot:appointment="data">
      <div class="small">
        {{ data.row | optional('lastDealDate.date_start') | datetime }}
      </div>
    </template>

    <template v-slot:actions="data">
      <div class="d-flex justify-content-end">

        <el-tooltip :content="$t('common.data_detail')"
                    :open-delay="300"
                    placement="top">
          <base-button
            class="mx-1"
            @click="$router.push({name: 'deals.to_confirm.show', params: {id : data.row.id }})"
            size="sm"
            link
            icon
          >
            <octo-icon icon="right-arrow"/>
          </base-button>
        </el-tooltip>
      </div>

    </template>

    <template slot="custom-filters">

    </template>
  </octo-table>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import {endpoints} from "@/routes/endpoints";
import {mapGetters} from "vuex";
import {permissionService} from "@/util/permission-service";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Badge from "@/components/Badge";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "DealToConfirmDatatable",
  components: {LabelThemeComponent, Badge, OctoTable, OctoIcon},
  data() {
    return {
      name: 'DealToConfirmDatatable',
      endpoints: endpoints,
      filters: {},
      fields: [
        {prop: 'registry', label: 'name', slot: true, align: 'left'},
        {prop: 'status', label: 'status', slot: true, align: 'center'},
        {prop: 'date', label: 'date', slot: true, align: 'center'},
        {prop: 'appointment', label: 'appointment', slot: true, align: 'center'},
        {prop: 'actions', label: 'actions', minWidth: 50, slot: true},
      ],
      permissionService: permissionService
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users',
      getFilters: 'common/datatableFilters',
    }),
  },
}
</script>

<style scoped>

</style>
