<template>
    <div class="row">
      <div class="col-12">
        <deal-to-confirm-datatable/>
      </div>
    </div>
</template>

<script>

import DealToConfirmDatatable from "@/pages/Deals/datatables/DealToConfirmDatatable";
export default {
  name: "IndexDealsToConfirmPage",
  components: {DealToConfirmDatatable}
}
</script>

<style scoped lang="scss">

</style>
